.collapse_option {
  color: #2f80ed;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  height: 24px;
  line-height: 24px;
  cursor: pointer;
}
.collapse_image {
  display: block;
  height: 14px;
  width: 14px;
  color: #2f80ed;
  margin-right: 4px;
  margin-top: 5px;
}
