.header_title {
  color: #828282;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
}

.header_value {
  color: #333333;
  font-weight: 600;
  font-size: 16px;
}

.header_logo {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.collapsible_columns {
  width: calc((100% - 410px) / 4);
}

.notes .css-5xn5lu-MuiInputBase-root-MuiInput-root:before {
  border: none;
  content: none;
}

.notes .css-5xn5lu-MuiInputBase-root-MuiInput-root:after {
  border: none;
}

.notes .MuiInputBase-colorPrimary {
  margin-top: 0px;
}

.collapsible_table .MuiDataGrid-columnHeaders {
  background-color: inherit !important;
}
.dashboard_options {
  font-size: 14px;
}
.dashboard_options .MuiSvgIcon-root {
  margin-right: 23px;
}

.dashboard_stats {
  content: "";
  display: flex;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.dashboard_stats_ready {
  background-color: #27ae60;
}

.dashboard_stats_closed {
  background-color: #969696;
}

.dashboard_stats_submitted {
  background-color: #f5cb4c;
}

.dashboard_stats_inPreparation {
  background-color: #56ccf2;
}

.dashboard_stats_total {
  display: none;
}

.totalStats {
  font-size: 14px;
  font-weight: 600;
  color: #333333;
}

.header_column {
  cursor: pointer;
  overflow: scroll !important;
}

.header_column::-webkit-scrollbar {
  display: none;
}

.totalStatsValue {
  width: 25px;
  height: 25px;
  background: #f2f2f2;
  font-size: 14px;
  font-weight: 500 !important;
}

.project_status_block {
  font-size: 14px;
  color: #333333;
  height: 44px;
  line-height: 44px;
  padding-right: 10px;
  background-color: #ffffff;
  width: max-content;
  border-radius: 20px;
  border-color: #e0e0e0;
}

.project_status_block::before {
  content: " ";
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 8px;
}

.project_in_preparation::before {
  background-color: #56ccf2;
}

.project_responding::before {
  background-color: #28d96f;
}

.project_agency::before {
  background-color: #bb6bd9;
}
