.modal_container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed !important;
  left: 50% !important;
  top: 50% !important;
  background: #ffffff;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  width: max-content;
  height: max-content;
}
.modal_container .MuiBackdrop-root {
  position: initial;
}
.modal_content {
  border: 1px solid #e5e5e5;
  background-color: white !important;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  /* max-height: calc(~"100vh - 85px"); */
  overflow: auto;
  box-sizing: border-box;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal_content:focus {
  outline: none;
}

.spinnerWrapper {
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
