.budget_cell > fieldset {
  border: none;
  color: #333333;
}
.budget_cell {
  color: #333333;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}

.budget_cell
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: #333333;
}

.budget_cell .css-1x5jdmq.Mui-disable {
  -webkit-text-fill-color: #333333 !important;
}

.budget_cell .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0;
  padding-left: 6px;
}

.budget_cell .css-1x5jdmq {
  padding: 0;
  padding-left: 6px;
  -webkit-text-fill-color: #333333 !important;
}
.disabled_input {
  border: none;
  padding-left: 0px !important;
  margin-top: 0px !important;
}

.active_Input {
  border: 1px solid #bdbdbd;
  border-radius: 4px;
}
.budget_cell_bold {
  font-weight: 700 !important;
}
