.main_layout {
  height: 100vh;
  width: 100%;
}

.sider {
  background: #f1f1f1;
  box-shadow: 1px 0px 0px #cac7c7;
  width: 10%;
  max-width: 64px;
}

.main_wrapper {
  width: calc(100% - 64px);
}

.logo {
  width: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 36px;

  display: flex;
  justify-content: center;
}

.menu {
  position: relative;
  margin-left: 50%;
  transform: translate(-50%);
  width: 100%;
}

.avatar {
  width: 100%;
  display: flex;
  justify-content: center;
}
