.note_owner_avatar {
  width: 34px;
  height: 34px;
  margin-right: 10px;
}

.notes_container {
  max-height: 270px;
  overflow: auto;
}

.note_container {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 20px;
  padding-top: 20px;
  justify-content: space-between;
}

.note_wrapper {
  display: flex;
  flex-direction: row;
}

.note_owner {
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #333333 !important;
  line-height: 17px !important;
  margin-bottom: -5px !important;
}
.note_createdAt {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 15px !important;
  color: #828282;
}

.note_info_header {
  margin-bottom: 10px;
}
