.statusBlock {
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  width: max-content;
  padding-right: 10px;
}
.statusBlock::before {
  content: " ";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 5px;

  margin-left: 8px;
  margin-right: 8px;
}
.in_preparation {
  background-color: #e6f7fd;
}

.in_preparation::before {
  background-color: #56ccf2;
}

.responding {
  background-color: #dff3e7;
}
.responding::before {
  background-color: #27ae60;
}
.ready {
  background-color: #dff3e7;
}
.ready::before {
  background-color: #27ae60;
}

.closed {
  background-color: #f0f0f0;
}
.closed::before {
  background-color: #969696;
}
.on_hold {
  background-color: #f0eef6;
}
.on_hold::before {
  background-color: #9992af;
}
.archived {
  background-color: #fbe2e2;
}
.archived::before {
  background-color: #eb5757;
}

.agency {
  background-color: #fdf5dc;
}
.agency::before {
  background-color: #f5cb4c;
}
.submitted {
  background-color: #fdf5dc;
}
.submitted::before {
  background-color: #f5cb4c;
}
