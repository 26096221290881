* {
  font-family: "Inter", sans-serif;
}

.madalHeader {
  position: relative;
  padding-bottom: 15px;
  padding-top: 15px;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 75px;
}

.headerDivider {
  border-bottom: 1px solid #e9e9e9;
}

.close {
  position: absolute;
  right: 20px;
  top: 23px;
  cursor: pointer;
  z-index: 1000;
  width: 28px;
  height: 28px;
  background: #f6f7f9;
  text-align: center;
  border-radius: 4px;
}

.close_button {
  display: inline-block;
  color: inherit;
  height: 8px !important;
  width: 8px !important;
  user-select: none;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.modal_icon {
  height: 45px;
  width: 45px;
  margin-right: 15px;
}

.modal_header_wrapper {
  display: flex;
  flex-direction: row;
}

.modal_description {
  font-size: 14px !important;
  font-weight: 400;
}

.topic {
  font-weight: 600 !important;
}
