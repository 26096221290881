.button {
  width: auto;
  height: 32px !important;
  border: 1px solid #bdbdbd !important;
  font-family: "Inter", sans-serif;
  font-weight: 400 !important;
  font-size: 14px;
  text-transform: capitalize !important;
  color: #828282 !important;
  margin-right: 10px !important;
}
.button:hover {
  background-color: #333333 !important;
  border: 1px solid #333333 !important;
  color: white !important;
}
.button.active {
  background-color: #333333 !important;
  border: 1px solid #333333 !important;
  color: white !important;
}
