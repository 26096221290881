.registration_form {
  width: 400px;
  margin: 0 auto;
  top: 356px;
}
.email_field {
  width: 400px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
}
.form-control {
  font-family: "Inter", sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  height: 45px !important;
  margin-bottom: 15px;
}
.password_field {
  width: 400px;
  height: 45px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
}
.control-label--hidden {
  display: none;
}
.forgot_password {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  width: 145%;
  margin-top: 15px;
}
.form_select {
  text-align: start;
}
.forgot_password a {
  color: #2e2e2e;
  text-decoration: none;
  cursor: pointer;
}
.remember_me {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  width: 55%;
  margin-top: 15px;
}
.form-check-label input:checked {
  background-color: #2e2e2e;
  border-color: #2e2e2e;
}
.btn {
  width: 400px !important;
  height: 45px;
  line-height: 45px;
  margin-top: 30px;
  background-color: #2e2e2e !important;
  opacity: 1 !important;
  border-color: #2e2e2e !important;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
}
.login_header {
  margin-bottom: 45px;
}
.login_header h2 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 27px;
  height: 32px;
  line-height: 32px;
  color: #2e2e2e;
}
.login_header p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  height: 17px;
  line-height: 17px;
  color: #2e2e2e;
}
