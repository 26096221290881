.button_wrapper {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  background-color: #f6f7f9;
  height: 70px;
  line-height: 70px;
  align-items: center;
  padding: 15px 19px;
}

.button_container {
  margin: 5px !important;
  height: 32px;
  padding: 4px 8px !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-family: "Inter", sans-serif !important;
}

.black_btn {
  background: #333333 !important;
  color: #ffffff !important;
}

.white_btn {
  background: #ffffff !important;
  border: 1px solid #333333 !important;
  color: #333333 !important;
}
