.reminder_label {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 6px;
  color: #2e2e2e;
}

.reminder_date_picker {
  box-sizing: border-box;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  margin-top: 15px !important;
}

.reminder_date_picker .MuiInputBase-formControl {
  border: 1px solid #bdbdbd;
}

.reminder_date_picker fieldset {
  border: none;
}
