.MuiInputBase-colorPrimary {
  margin-top: -15px;
}
.MuiTablePagination-actions {
  margin-top: -15px;
}
.MuiDataGrid-footerContainer {
  height: 45px;
  padding-top: 20px;
  background-color: #f6f7f9;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  color: #333333;
  font-weight: 400;
}
.MuiDataGrid-columnHeaders {
  background-color: #f6f7f9;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  color: #646464;
  font-weight: 700;
}
.MuiDataGrid-main {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #333333;
  font-weight: 400;
}
.MuiTablePagination-select {
  background-color: #ffffff !important;
  border: 1px solid #bdbdbd !important;
  border-radius: 4px !important;
}
.MuiToolbar-root {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #333333;
  font-weight: 400;
}
.remove_logo {
  max-width: 400px;
  position: relative;
}
.date_field span {
  height: calc(100% - 15px);
}
.is-flipped .choices__list--dropdown {
  border-radius: 0.25rem 0.25rem 0 0;
  bottom: 0% !important;
  margin-bottom: -1px;
  margin-top: 0;
  top: 100% !important;
  overflow: auto;
  height: 300px;
}
