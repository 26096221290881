.main_container {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
}
.wrapper {
  max-width: 400px;
  position: absolute;
  left: 50%;
  margin-top: 90px;
  transform: translate(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 180px);
}
.company_logo {
  margin-bottom: 90px;
  display: flex;
  /* justify-content: center; */
}
.logo {
  width: 70px !important;
  height: 70px;
}
.need_help {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  height: 15px;
  line-height: 15px;
  color: #2e2e2e;
  margin-top: 30px;
}
.need_help a {
  font-weight: bold;
  color: #2e2e2e;
  text-decoration: none;
}
.vector {
  width: 16px;
  height: 16px;
  margin: 0px 8px;
}
